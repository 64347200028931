import React from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import { Status } from '../model/RoutingSpec';
import { ReactComponent as IconSpin } from '../../images/ic-loading.svg';
import { ReactComponent as IconCross } from '../../images/ic-cross.svg';
import { ReactComponent as IconTick } from '../../images/ic-tick.svg';

export const statusColor = (status: Status | undefined, theme: TKUITheme) =>
    status === "succeeded" ? theme.colorSuccess :
        status === "failed" || status === "error" ? theme.colorError :
            status === "skipped" ? theme.colorWarning :
                status === "running" ? theme.colorInfo : black(1);

const statusIconStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.center,
        ...genStyles.alignCenter,
        ...genStyles.noShrink,
        width: '18px',
        height: '18px',
        color: ({ status }) => statusColor(status, theme),
        '& svg path, svg rect': {
            fill: ({ status }) => statusColor(status, theme)
        },
        border: ({ status }) => status === "running" ? "none" : '1px solid ' + statusColor(status, theme),
        ...genStyles.borderRadius(50, '%'),
        padding: '2px',
        '& svg': {
            width: '100%',
            height: '100%'
        }
    },
    iconSpin: {
        ...genStyles.animateSpin
    }
})

type IStyle = ReturnType<typeof statusIconStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    status?: Status;
}

const StatusIcon: React.FunctionComponent<IProps> =
    ({ status, classes, theme, t }) => {
        let statusIcon;
        switch (status) {
            case "running":
                statusIcon = <IconSpin className={classes.iconSpin} />;
                break;
            case "succeeded":
                statusIcon = <IconTick />;
                break;
            case "failed":
                statusIcon = <IconCross />;
                break;
            case "error":
                statusIcon = "E";
                break;
            case "skipped":
                statusIcon = "?";
                break;
            default:    // status === undefined, which means not run.
                statusIcon = null;
        }
        return (
            <div className={classes.main}>
                {statusIcon}
            </div>
        );
    };


export default withStyles(StatusIcon, statusIconStyle);