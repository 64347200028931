import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-time-format-timezone/build/browserified/date-time-format-timezone-all-zones-no-locale-min';
import React from 'react';
import ReactDOM from 'react-dom';
import TripPlannerAppDev from './app/TripPlannerAppDev';
import { Environment } from 'tripkit-react';

if (process.env.NODE_ENV === 'development' || Environment.isBeta()) {
    // require("./mock/mock.js");
}

const containerElement = document.getElementById("tripgo-planner") as HTMLElement;
ReactDOM.render(<TripPlannerAppDev />, containerElement);