import "reflect-metadata";
import TripGoApi from "tripkit-react/dist/api/TripGoApi.js";
import NetworkUtil from "tripkit-react/dist/util/NetworkUtil.js";
import TKShareHelper from "tripkit-react/dist/share/TKShareHelper.js";
import RoutingResults from "tripkit-react/dist/model/trip/RoutingResults.js";
import RoutingQuery from "tripkit-react/dist/model/RoutingQuery.js";
import { tripHelpers, printFunctionBody, prepareQuery, routingQueryToUrl } from "./SpecHelpers.js";
import { RegionsData } from "tripkit-react";

TripGoApi.apiKey = "790892d5eae024712cfd8616496d7317";

/**
 * 
 * @returns Promise<{result: string, error: Error}[]>[]
 */
function runSpec(spec) {
    const { name, issue, created, createdBy, checks } = spec;
    return ({
        ...spec,
        checks: checks.map(runCheck)
    });
}

/**
 * 
 * @returns Promise<{result: string, error: Error}[]>
 */
export function runCheck(check) {
    const { query, conditions } = check;
    const queryResultP = RegionsData.instance.requireRegions().then(() => {
        let routingQuery;
        if (query.url) {
            routingQuery = TKShareHelper.parseSharedQueryUrl(query.url) ||
                TKShareHelper.parseRoutingQueryUrl(query.url.substring(query.url.indexOf("?")));
        } else {
            routingQuery = TKShareHelper.parseRoutingQueryJSON(query);
        }
        console.log(routingQuery);
        routingQuery = prepareQuery(routingQuery);
        const preparedQueryUrl = routingQueryToUrl(routingQuery);
        return TripGoApi.apiCallT(preparedQueryUrl, NetworkUtil.MethodType.GET, RoutingResults);
    });
    queryResultP.then(queryResult => checkResult.queryResult = queryResult);
    const resolvedConditions = conditions.map(condition => {
        const { filter, requirement } = condition;
        const result = {
            status: "running"
        };
        const resultP = queryResultP.then(({ groups: trips }) => {
            let satisfies;
            try {                
                switch (filter) {
                    case "all":
                        satisfies = trips.every(trip => requirement(tripHelpers(trip)));
                        break;
                    case "any":
                        satisfies = trips.some(trip => requirement(tripHelpers(trip)));
                        break;
                    case "best":
                        satisfies = trips.length > 0 && requirement(tripHelpers(trips[0]));
                        break;
                    case "none":
                        satisfies = trips.length > 0 && !trips.some(trip => requirement(tripHelpers(trip)));
                        break;
                    default:
                        const filtered = trips.filter(trip => filter(tripHelpers(trip)));
                        if (filtered.length === 0) {
                            result.status = "skipped";
                            return result;
                        }
                        satisfies = filtered.every(trip => requirement(tripHelpers(trip)));
                }
                result.status = satisfies ? "succeeded" : "failed" // Still need to model the skipped; 
                return result;
            } catch (e) {
                result.status = "error";
                result.error = e.toString();
                // .slice("Error: ".length);
                console.log(e);
                return result;
            }
        }).catch(e => {
            result.status = "error";
            result.error = e.toString();
            // .slice("Error: ".length);
            console.log(e);
            return result;
        });
        return ({
            ...condition,
            result,
            resultP
        })
    });
    const checkResult = {
        ...check,
        conditions: resolvedConditions,
        queryResultP
    }
    return checkResult;
}

export { runSpec }