import React, { useContext, useState } from 'react';
import TKUICard, { CardPresentation } from 'tripkit-react/dist/card/TKUICard';
import TKUIContextMenu from 'tripkit-react/dist/card/TKUIContextMenu';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import { Check, RoutingSpec } from '../model/RoutingSpec';
import { printFunctionBody } from '../runner/SpecHelpers';
import StatusIcon, { statusColor } from './StatusIcon';
import copy from 'copy-to-clipboard';
import TKUIButton, { TKUIButtonType } from 'tripkit-react/dist/buttons/TKUIButton';
import { RoutingResultsContext } from 'tripkit-react/dist/trip-planner/RoutingResultsProvider';


const routingSpecViewStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        margin: '16px'
    },
    check: {
        ...genStyles.flex,
        ...genStyles.column,
        border: '1px solid ' + black(2, theme.isDark),
        ...genStyles.borderRadius(8),
        padding: '10px',
        marginBottom: '16px'
    },
    checkHeader: {
        ...genStyles.flex,
        ...genStyles.alignStart
    },
    checkTitle: {
        ...theme.textWeightBold,
        ...genStyles.grow,
        marginLeft: '10px'
    },
    checkStatus: {
        marginRight: '10px'
    },
    condition: {
        ...genStyles.flex,
        ...genStyles.column,
        marginTop: '10px',
        ...genStyles.fontS
    },
    conditionDescription: {
        fontStyle: 'italic'
    },
    conditionBody: {
        ...genStyles.flex,
        ...genStyles.alignCenter
    },
    conditionRequirement: {
        ...genStyles.grow
    }
})

type IStyle = ReturnType<typeof routingSpecViewStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    spec: RoutingSpec;
    onRunCheck?: (checkI: number) => void;
    onRunSpec?: () => void;
    onRequestClose?: () => void;
}

export const checkStatus = (check: Check) =>
    check.conditions.every(condition => condition.result?.status === "succeeded") ? "succeeded" :
        check.conditions.some(condition => condition.result?.status === "failed") ? "failed" :
            check.conditions.some(condition => condition.result?.status === "error") ? "error" :
                check.conditions.some(condition => condition.result?.status === "skipped") ? "skipped" :
                    check.conditions.some(condition => condition.result?.status === "running") ? "running" :
                        undefined;

const RoutingSpecView: React.FunctionComponent<IProps> =
    ({ spec, onRunCheck, onRunSpec, classes, theme, onRequestClose }) => {
        const [contextMenuPos, setContextMenuPos] = useState<[number, number] | undefined>(undefined);
        const [contextMenuTargetCheckI, setContextMenuTargetCheckI] = useState<number | undefined>(undefined);
        const copyToClipboard = (text: string) => {
            // On iOS, copy from copy-to-clipboard just works if it's called associated to a recognized event, like
            // a click in our case.
            copy(text, { message: "Unable to copy" });
        };
        const { onTripJsonUrl } = useContext(RoutingResultsContext);
        return (
            <TKUICard
                title={spec.name}
                onRequestClose={onRequestClose}
                presentation={CardPresentation.SLIDE_UP}
            >
                <div className={classes.main}>
                    {spec.checks.map((check, i) => {
                        const { name } = check;
                        return (
                            <div
                                className={classes.check}
                                key={i}
                                onContextMenu={e => {
                                    e.preventDefault();
                                    setContextMenuPos([e.clientX, e.clientY]);
                                    setContextMenuTargetCheckI(i);
                                }}
                            >
                                <div className={classes.checkHeader}>
                                    <StatusIcon status={checkStatus(check)} />                                    
                                    <div className={classes.checkTitle}>
                                        {name}
                                    </div>
                                </div>
                                {check.conditions.map(({ description, filter, requirement, result }, i) =>
                                    <div className={classes.condition} style={{ color: statusColor(result?.status, theme) }} key={i}>
                                        {description &&
                                            <div className={classes.conditionDescription}>
                                                {description}
                                            </div>}
                                        <div className={classes.conditionBody}>
                                            <div className={classes.conditionRequirement}>
                                                {`${printFunctionBody(filter)}: ${printFunctionBody(requirement)}`}
                                            </div>
                                            <div title={result?.status === "error" ? result.error : undefined}>
                                                <StatusIcon status={result?.status === "running" ? undefined : result?.status} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {check.queryResult?.groups &&
                                    <TKUIButton
                                        text={`show ${check.queryResult.groups?.length} trip groups`}
                                        type={TKUIButtonType.PRIMARY_LINK}
                                        onClick={() => onTripJsonUrl(check.queryResult)}
                                    />}
                            </div>
                        );
                    })}
                </div>
                <TKUIContextMenu
                    items={[
                        { label: "Run this", onClick: () => onRunCheck?.(contextMenuTargetCheckI!) },
                        { label: "Run all", onClick: () => onRunSpec?.() },
                        { label: "Copy routing.json URL", onClick: () => copyToClipboard(spec.checks[contextMenuTargetCheckI!].query.url) }
                    ]}
                    position={contextMenuPos}
                    onRequestClose={() => {
                        setContextMenuPos(undefined);
                        setContextMenuTargetCheckI(undefined);
                    }}
                    closeIfNotOverAfter={3000}
                />
            </TKUICard>
        );
    };


export default withStyles(RoutingSpecView, routingSpecViewStyle);