import TransportUtil from "tripkit-react/dist/trip/TransportUtil.js";
import DateTimeUtil from "tripkit-react/dist/util/DateTimeUtil.js";
import { TimePreference } from "tripkit-react/dist/model/RoutingQuery.js";
import { RegionsData } from "tripkit-react/dist/data/RegionsData.js";
import { TripGoApi } from "tripkit-react";
// import ServiceDeparture from "tripkit-react/dist/model/service/ServiceDeparture.js";
// import Color from "tripkit-react/dist/model/trip/Color.js";
// import ModeInfo from "tripkit-react/dist/model/trip/ModeInfo";
// import StopLocation from "tripkit-react/dist/model/StopLocation";
// import RTServiceDepartureUpdate from "tripkit-react/dist/model/service/RTServiceDepartureUpdate";
// import ServiceDetail from "tripkit-react/dist/model/service/ServiceDetail";
// import RealTimeVehicle from "tripkit-react/dist/model/service/RealTimeVehicle";
// import RealTimeAlert, { AlertSeverity } from "tripkit-react/dist/model/service/RealTimeAlert";
// import { alertSeverity } from "tripkit-react/dist/model/trip/Segment";
// import SegmentTemplate, { SegmentType, Visibility } from "tripkit-react/dist/model/trip/SegmentTemplate";
// import Location from "tripkit-react/dist/model/Location";
// import ServiceShape from "tripkit-react/dist/model/trip/ServiceShape";
// import Street from "tripkit-react/dist/model/trip/Street";

export const printFunctionBody = fn => {
    let fnS = fn.toString();
    fnS = fnS.includes("=>") ? fnS.slice(fnS.indexOf("=>") + "=>".length) : fnS;
    if (fnS.includes("function")) {
        fnS = fnS.slice(fnS.indexOf("{") + 1, fnS.lastIndexOf("}"));
        fnS = fnS.slice(fnS.indexOf("return") + "return".length).trim();
    }
    fnS = fnS.replace(";", "");
    return fnS;
}

const segmentInterchanges = segment => {
    const candidates = [];
    if (!segment.isContinuation) {
        segment.stopCode && candidates.push(segment.stopCode);   // It should be scheduledStartStopCode, in SegmentTemplate class in iOS, but don't know how to calculate / get it.
        segment.from.address && candidates.push(segment.from.address);
    }
    if (segment.nextSegment?.isContinuation !== true) {
        segment.endStopCode && candidates.push(segment.endStopCode);   // It should be scheduledStartStopCode, in SegmentTemplate class in iOS, but don't know how to calculate / get it.
        segment.to.address && candidates.push(segment.to.address);
    }
    return candidates;
}

export const tripHelpers = (trip) => {
    const modes = trip.segments.filter(segment => !segment.isStationary())
        .map(segment => segment.modeIdentifier);
    const modeIdentifiers = trip.segments.reduce((modeIdentifiers, segment) =>
        modeIdentifiers.concat([segment.modeIdentifier, segment.modeInfo.identifier]), []);
    const segments = trip.segments;
    const segmentsFc = mode => trip.segments.filter(segment => segment.modeIdentifier && TransportUtil.isSubMode(segment.modeIdentifier, mode));
    const modeDistances = mode => trip.segments
        .filter(segment => segment.modeIdentifier && TransportUtil.isSubMode(segment.modeIdentifier, mode) && segment.metres !== undefined)
        .map(segment => segment.metres);
    const services = segmentsFc('pt_pub').reduce((services, ptSegment) => services.concat([ptSegment.serviceName, ptSegment.serviceNumber]), []);
    const interchanges = segments.reduce((result, segment) => result.concat(segmentInterchanges(segment)), []);
    const count = array => array.length;
    const contains = (strArray, str) => strArray.includes(str);
    const _segmentUses = (segment, usage) => {
        switch (usage) {
            case "carpark":
                return segment.isStationary();
            default:
                return segment.streets?.some(street => street.name === usage) ?? false;
        }
    }
    const _tripUses = (usage, mode) => {
        const candidates = mode ? segmentsFc(mode) : segments;
        return candidates.some(segment => _segmentUses(segment, usage));
    }
    const uses = usage => _tripUses(usage);
    const distance = segments.reduce((distance, segment) => distance + (segment.metres ?? 0), 0)
    const duration = trip.duration;
    const km = km => km * 1000; // km to metres
    const h = h => h * 3600; // h to seconds
    const max = nums => nums.reduce((a, b) => Math.max(a, b), 0);
    const min = nums => nums.reduce((a, b) => Math.min(a, b), Number.MAX_SAFE_INTEGER);
    const sum = nums => nums.reduce((a, b) => a + b, 0);
    return ({
        modes, modeIdentifiers, segments, segmentsFc, modeDistances, services, count, contains, uses, interchanges,
        distance, duration,
        km, h,
        min, max, sum
    });
}

export const prepareQuery = query => {
    const timezone = RegionsData.instance.getRegion(query.from)?.timezone;
    console.log(timezone);
    query.time = afterNowAtSameTimeAndWeekday(query.time, timezone);
    return query;
}

const afterNowAtSameTimeAndWeekday = (time, timezone = DateTimeUtil.defaultTZ) => {
    console.log("time: " + time.format() + " " + time.format("dddd"));
    time = DateTimeUtil.momentFromTimeTZ(time.valueOf(), timezone);
    const now = DateTimeUtil.getNow(timezone);
    // Don't know why for test #16829 Mountainbike route the timeTZ prints without the timezone!!!
    console.log("timeTZ: " + time.format() + " " + time.format("dddd"));
    console.log("now: " + now.format() + " " + now.format("dddd"));
    let result;
    if (time.valueOf() < now.valueOf()) { // Time is in the past
        const nowInDesiredTime = DateTimeUtil.getNow(timezone).hours(time.hours()).minutes(time.minutes()).seconds(time.seconds()).milliseconds(time.milliseconds());
        if (nowInDesiredTime.valueOf() < now.valueOf()) { // If setting the proper time to now goes to the past, then I need to move to the next day.
            nowInDesiredTime.add(1, 'days');
        }
        console.log("nowInDesiredTime: " + nowInDesiredTime.format() + " " + nowInDesiredTime.format("dddd"));
        const dayINeed = time.isoWeekday();
        const dayIHave = nowInDesiredTime.isoWeekday();
        // if we haven't yet passed the day of the week that I need:
        if (dayIHave <= dayINeed) {
            // then just give me this week's instance of that day. If I'm on that same day, then go to the else, to forward 1 week.
            result = nowInDesiredTime.isoWeekday(dayINeed);
        } else {
            // otherwise, give me *next week's* instance of that same day
            result = nowInDesiredTime.add(1, 'weeks').isoWeekday(dayINeed);
        }
        // result.hours(time.hours()).minutes(time.minutes()).seconds(time.seconds()).milliseconds(time.milliseconds());
    } else {
        result = time;
    }
    console.log("result: " + result.format() + " " + result.format("dddd"));
    if (!(result.valueOf() > now.valueOf() && result.isoWeekday() === time.isoWeekday()
        && result.hours() === time.hours() && result.minutes() === time.minutes()
        && result.seconds() === time.seconds() && result.milliseconds() === time.milliseconds())) {
        console.error("Error amending query time to be in the future at the same weekday and time");
        // For now just show error in console. Then can throw, and catch on SpecRunner to make test status to be 'error'.
        // throw new Error("Error amending query time to be in the future at the same weekday and time");
    }
    return result;
}

export const routingQueryToUrl = query => {
    if (query.from === null || query.to === null) {
        return "";
    }
    // Add version parameter in case it doesn't come. See if I should have some default parameters.
    const additional = { v: TripGoApi.apiVersion, allModes: true, ...query.additional };
    const additionalParams = Object.keys(additional).reduce((params, key) => {
        if (Array.isArray(additional[key])) {
            return params + additional[key].reduce((arrayParam, value) => arrayParam + '&' + key + '=' + value, "");
        } else {
            return params + '&' + key + '=' + additional[key];
        }
    }, "");
    const fromAddressQuoted = query.from.address ? `"${query.from.address}}"` : "";
    const toAddressQuoted = query.to.address ? `"${query.to.address}}"` : "";
    return encodeURI("routing.json" +
        `?from=(${query.from.lat},${query.from.lng})${fromAddressQuoted}` +
        `&to=(${query.to.lat},${query.to.lng})${toAddressQuoted}` +
        `&${query.timePref === TimePreference.ARRIVE ? "arriveBefore" : "departAfter"}=${Math.floor(query.time.valueOf() / 1000)}` +
        additionalParams);
}