import React, { useState, useEffect, Fragment, useMemo } from 'react';
import TKRoot from "tripkit-react/dist/config/TKRoot";
import { CSSProps, TKUIWithStyle } from "tripkit-react/dist/jss/StyleHelper";
import TKStateUrl from "tripkit-react/dist/state/TKStateUrl";
import TKUISettingLink from "tripkit-react/dist/options/TKUISettingLink";
import { IOptionsContext, default as OptionsProvider, OptionsContext } from "tripkit-react/dist/options/OptionsProvider";
import { getApiKey, default as TGUIDevSettingsView, getServer, setPredefinedApiKeys, setPredefinedServers, predefinedServers, predefinedApiKeys } from "../../../../options/TGUIDevSettingsView";
import TKUISettingSection from "tripkit-react/dist/options/TKUISettingSection";  //
import LatLng from "tripkit-react/dist/model/LatLng";
import TKUITripPlanner from "tripkit-react/dist/trip-planner/TKUITripPlanner";
import RoutingSpecsView, { RoutingSpecFile } from '../../../../tripgo_api_specs_kit/ui/RoutingSpecsView';
import { buildConfig } from './config';
import { RoutingSpec } from '../../../../tripgo_api_specs_kit/model/RoutingSpec';
import TKUIButton, { TKUIButtonType } from 'tripkit-react/dist/buttons/TKUIButton';
import { buttonStylesOverride } from 'tripkit-react/dist/sidebar/TKUISidebar';
import { ReactComponent as IconDevelopment } from '../images/ic-laptop-settings.svg';
import TripGoApi from 'tripkit-react/dist/api/TripGoApi';

export interface IClientProps extends TKUIWithStyle<IStyle, IProps> {
}

export interface IStyle {
    main: CSSProps<IProps>;
}

interface IProps { }

function loadScript(url, options) {
    // Adding the script tag to the head as suggested before
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    if (options) {
        // Then bind the event to the callback function.
        // There are several events for cross browser compatibility.
        (script as any).onreadystatechange = options.callback;
        script.onload = options.callback;
        script.defer = options.defer;
        if (options.async !== undefined) { // Since script.async = true by default, and don't want to change that.
            script.async = options.async;
        }
    }
    // Fire the loading
    head.appendChild(script);
}

const hostname = (window as any).location.hostname;
const isSGFleet = hostname.includes("sgfleet.");
if (isSGFleet) {
    setPredefinedApiKeys({
        'sgfleet': "bf3d2c318292c7769e8c987b06b5113d",
        ...predefinedApiKeys
    });
    setPredefinedServers({
        'beta': TripGoApi.SATAPP_BETA,
        ...predefinedServers as any
    });
}

const TripPlanerApp: React.FunctionComponent<IProps> = (props: IProps) => {

    const [specFiles, setSpecFiles] = useState<RoutingSpecFile[]>([]);
    const loadSpecFile = name =>
        loadScript(`specs/${name}.js`, { callback: () => setSpecFiles(specFiles => specFiles.concat([{ name, specs: window[`${name.replaceAll("-", "_")}_specs`] as RoutingSpec[] }])) });

    useEffect(() => {
        loadSpecFile("lcc-sow1");
        loadSpecFile("tfgm-maintenance");
        loadSpecFile("csa-multimodal");
    }, []);

    const renderCustomSettings = () =>
        <TKUISettingSection>
            <TKUISettingLink text={"Development"} onClick={() => setShowDevSettings(true)} />
        </TKUISettingSection>;

    const renderMenuItemsBuilder = onRequestClose => defaultItems => (
        <Fragment>
            {defaultItems}
            <TKUIButton
                text={"Development"}
                icon={<IconDevelopment style={{ width: '22px', height: '22px' }} />}
                type={TKUIButtonType.SECONDARY}
                styles={buttonStylesOverride}
                onClick={() => {
                    setShowDevSettings(true);
                    onRequestClose();
                }}
            />
        </Fragment>
    )

    const [showDevSettings, setShowDevSettings] = useState<boolean>(false);
    const [showLoadTrips, setShowLoadTrips] = useState<boolean>(false);
    const [showRoutingSpecs, setShowRoutingSpecs] = useState<boolean>(false);
    const devSettingsView = showDevSettings &&
        <TGUIDevSettingsView
            showLoadTrips={showLoadTrips}
            setShowLoadTrips={setShowLoadTrips}
            onOpenRoutingSpecs={() => {
                setShowRoutingSpecs(true);
            }}
            onRequestClose={() => setShowDevSettings(false)}
        />;
    const routingSpecsView = showRoutingSpecs &&
        <RoutingSpecsView
            specFiles={specFiles}
            onRequestClose={() => setShowRoutingSpecs(false)}
        />


    let userLocationPromise = (window as any).tKUserLocationPromise ?
        (window as any).tKUserLocationPromise
            .then((userCoords: [number, number]) => LatLng.createLatLng(userCoords[0], userCoords[1])) : undefined;

    if (window.location.pathname === '/world') {
        window.history.replaceState(null, '', '/');
        userLocationPromise = Promise.reject();
    }

    const config = useMemo(() => buildConfig({ renderCustomSettings, renderMenuItemsBuilder, hideSearch: showRoutingSpecs }), []);

    useEffect(() => {
        const keyEventListener = (zEvent: any) => {
            if (zEvent.shiftKey && zEvent.metaKey && (zEvent.key === "d")) {
                setShowDevSettings(true);
                zEvent.preventDefault();
            } else if (zEvent.shiftKey && zEvent.metaKey && (zEvent.key === "p" || zEvent.key === "l" || zEvent.key === "o")) {
                setShowLoadTrips(true);
                setShowDevSettings(true);
                zEvent.preventDefault();
            } else if (zEvent.shiftKey && zEvent.metaKey && (zEvent.key === "s")) {
                setShowRoutingSpecs(true);
                zEvent.preventDefault();
            }
        };
        document.addEventListener("keydown", keyEventListener);
        return () => {
            document.removeEventListener("keydown", keyEventListener);
        };
    }, []);

    // Obs: OptionsProvider used here and inside TKRoot keep their values at sync due to OptionsProvider subscribing to
    // OptionsData changes (See OptionsProvider:36). Try to avoid this so can remove that subscription.
    return (
        <OptionsProvider>
            <OptionsContext.Consumer>
                {(optionsContext: IOptionsContext) =>
                    <TKRoot config={{ ...config, apiKey: getApiKey(optionsContext.userProfile), apiServer: getServer(optionsContext.userProfile) }}>
                        <TKUITripPlanner userLocationPromise={userLocationPromise} />
                        <TKStateUrl />
                        {devSettingsView}
                        {routingSpecsView}
                    </TKRoot>
                }
            </OptionsContext.Consumer>
        </OptionsProvider>
    );
};

export default TripPlanerApp;