import React, { Fragment, useState } from 'react';
import TKUICard, { CardPresentation } from 'tripkit-react/dist/card/TKUICard';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TKUIContextMenu from 'tripkit-react/dist/card/TKUIContextMenu';
import { RoutingSpec } from '../model/RoutingSpec';
import { runCheck, runSpec } from '../runner/SpecRunner';
import RoutingSpecView, { checkStatus } from './RoutingSpecView';
import StatusIcon from './StatusIcon';
import { TKStateConsumer } from 'tripkit-react';


const routingSpecsViewStyle = (theme: TKUITheme) => ({
    main: {

    },
    section: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        margin: '16px',
        '&>*': {
            ...theme.divider
        }
    },
    sectionTitle: {
        fontFamily: 'monospace',
        margin: '0 -16px',
        padding: '10px 16px',
        background: black(5)
    },
    spec: {
        ...genStyles.flex,
        ...genStyles.column,
        padding: '16px 0',
        cursor: 'pointer'
    },
    specTitle: {

    },
    checks: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        '&>*': {
            marginRight: '10px'
        },
        marginTop: '5px'
    }
})

export interface RoutingSpecFile {
    name: string;
    specs: RoutingSpec[];
}

type IStyle = ReturnType<typeof routingSpecsViewStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    specFiles: RoutingSpecFile[];
    onRequestClose?: () => void;
}

const RoutingSpecsView: React.FunctionComponent<IProps> = ({ specFiles, onRequestClose, classes, ...otherProps }) => {
    const [specResults, setSpecResults] = useState<RoutingSpecFile[]>(specFiles);
    const onRunSpec = fileI => specI => {
        setSpecResults(specResults => {
            specResults[fileI].specs[specI] = runSpec(specResults[fileI].specs[specI]);
            // TODO: see if I can have a promise in the check object, though if conditions can be async (currently they couldn't), then it's better this way.            
            specResults[fileI].specs[specI].checks.forEach(check =>
                check.conditions[0]?.resultP?.then(() => {
                    setSpecResults(specResults => [...specResults]);
                })
            );
            return [...specResults];
        });
    };
    const onRunCheck = fileI => specI => checkI => {
        if (specI === undefined) {
            return;
        }
        specResults[fileI].specs[specI].checks[checkI] = runCheck(specResults[fileI].specs[specI].checks[checkI])
        setSpecResults(specResults);
        // TODO: see if I can have a promise in the check object, though if conditions can be async (currently they couldn't), then it's better this way.            
        specResults[fileI].specs[specI].checks[checkI].conditions[0]?.resultP?.then(() => {
            setSpecResults(specResults => [...specResults]);
        });
    };
    const onRunAll = () => {
        specResults.forEach((file, fileI) => file.specs.forEach((spec, specI) => onRunSpec(fileI)(specI)))
    }
    return (
        <TKStateConsumer>
            {tkstate => !tkstate.directionsView &&
                <TKUICard
                    title={"Routing Specs"}
                    onRequestClose={onRequestClose}
                    presentation={CardPresentation.SLIDE_UP}
                >
                    <div className={classes.main}>
                        {specResults.map((specFile, i) => {
                            const { name, specs } = specFile;
                            return (
                                <RoutingSpecsSection
                                    name={name}
                                    specs={specs}
                                    onRunCheck={onRunCheck(i)}
                                    onRunSpec={onRunSpec(i)}
                                    onRunAll={onRunAll}
                                    classes={classes}
                                    {...otherProps}
                                    key={i}
                                />
                            );
                        })}
                    </div>
                </TKUICard>
            }
        </TKStateConsumer>
    );
}

interface RoutingSpecsSectionProps extends TKUIWithClasses<IStyle, IProps> {
    name: string;
    specs: RoutingSpec[];
    onRunCheck: (specI: number) => (checkI: number) => void;
    onRunSpec: (specI: number) => void;
    onRunAll: () => void;
}

const RoutingSpecsSection: React.FunctionComponent<RoutingSpecsSectionProps> =
    ({ name, specs, onRunCheck, onRunSpec, onRunAll, classes }) => {
        const [selectedSpecI, setSelectedSpecI] = useState<number | undefined>(undefined);
        const [contextMenuPos, setContextMenuPos] = useState<[number, number] | undefined>(undefined);
        const [contextMenuTargetSpecI, setContextMenuTargetSpecI] = useState<number | undefined>(undefined);
        return (
            <Fragment>
                <div className={classes.section}>
                    <div className={classes.sectionTitle}>
                        {name}
                    </div>
                    {specs.map((spec, i) => {
                        const { name, issue, created, createdBy, checks } = spec;
                        return (
                            <div
                                className={classes.spec}
                                key={i}
                                onClick={() => setSelectedSpecI(i)}
                                onContextMenu={e => {
                                    e.preventDefault();
                                    setContextMenuPos([e.clientX, e.clientY]);
                                    setContextMenuTargetSpecI(i);
                                }}
                            >
                                <div className={classes.specTitle}>
                                    {name}
                                </div>
                                <div className={classes.checks}>
                                    {checks.map((check, j) => <StatusIcon status={checkStatus(check)} key={j} />)}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {
                    selectedSpecI !== undefined &&
                    <RoutingSpecView
                        spec={specs[selectedSpecI]}
                        onRunCheck={onRunCheck(selectedSpecI)}
                        onRunSpec={() => onRunSpec(selectedSpecI)}
                        onRequestClose={() => setSelectedSpecI(undefined)}
                    />
                }
                <TKUIContextMenu
                    items={[
                        { label: "Run this", onClick: () => onRunSpec(contextMenuTargetSpecI!) },
                        { label: "Run all", onClick: () => onRunAll() },
                        { label: "Open redmine", onClick: () => window.open(specs[contextMenuTargetSpecI!].issue, "_blank") }
                    ]}
                    position={contextMenuPos}
                    onRequestClose={() => {
                        setContextMenuPos(undefined);
                        setContextMenuTargetSpecI(undefined);
                    }}
                    closeIfNotOverAfter={3000}
                />
            </Fragment>
        );
    };


export default withStyles(RoutingSpecsView, routingSpecsViewStyle);